const useAssets = {
  // import i18n
  i18n: inject("i18n"),

  methods: {
    getImageFromAssets(imgFileDir) {
      return new URL(`../assets/${imgFileDir}`, import.meta.url).href
    },
    // options: ["dateOnly", "days", "months", "years", "timeOnly", "dateTime"]
    //
    // formateDateTimeToAr(dateTime, options = []) {
    //   // Handle invalid or null dateTime
    //   // alert(options)
    //   if (!dateTime) {
    //     return ""
    //   }

    //   try {
    //     const date = new Date(dateTime)

    //     // Check if date is valid
    //     if (isNaN(date.getTime())) {
    //       return ""
    //     }

    //     const day = new Intl.DateTimeFormat("en", { day: "numeric" }).format(
    //       date
    //     )
    //     const month = new Intl.DateTimeFormat("ar-EG", {
    //       month: "long"
    //     }).format(date)
    //     const year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
    //       date
    //     )

    //     // Format time
    //     const hour = date.getHours()
    //     const minute = date.getMinutes()
    //     const period = hour < 12 ? "صباحًا" : "مساءً"

    //     // Convert to 12-hour format
    //     const hour12 = hour % 12 || 12

    //     // Format time string
    //     const time = `${hour12.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")} ${period}`

    //     // Build result based on provided options
    //     let parts = []
    //     if (options.includes("d")) {
    //       parts.push(day)
    //     }
    //     if (options.includes("m")) {
    //       parts.push(month)
    //     }
    //     if (options.includes("y")) {
    //       parts.push(year)
    //     }
    //     if (options.includes("time")) {
    //       parts.push(time)
    //     }

    //     // If no specific options or date selected, return full date
    //     if (options.length === 0 || options.includes("date")) {
    //       return `${day} ${month} ${year}`
    //     }

    //     // If dateTime selected, return full date and time
    //     if (options.includes("dateTime")) {
    //       return `${day} ${month} ${year} - ${time}`
    //     }

    //     return parts.join(" ")
    //   } catch (error) {
    //     console.error("Error formatting date:", error)
    //     return ""
    //   }
    // },
    formateDateTimeToAr(dateTime, options = []) {
      if (!dateTime) return ""

      try {
        const date = new Date(dateTime)
        if (isNaN(date.getTime())) return ""

        // Format components
        const day = new Intl.DateTimeFormat("ar", { day: "numeric" }).format(
          date
        )
        const month = new Intl.DateTimeFormat("ar-EG", {
          month: "long"
        }).format(date)
        const hour = date.getHours()
        const minute = date.getMinutes()
        const period = hour < 12 ? "صباحًا" : "مساءً"
        const hour12 = hour % 12 || 12
        const time = `${hour12}:${minute.toString().padStart(2, "0")} ${period}`

        // Handle different format options
        if (options.includes("short")) {
          return `${day}${month}, ${time}`
        }

        if (options.includes("time-only")) {
          return time
        }

        if (options.includes("date-only")) {
          return `${day}${month}`
        }

        // Default format
        return `${day} ${month}, ${time}`
      } catch (error) {
        console.error("Error formatting date:", error)
        return ""
      }
    },
    formatDateToShortAr(dateTime) {
      if (!dateTime) return ""

      try {
        const date = new Date(dateTime)
        if (isNaN(date.getTime())) return ""

        // Format day in Arabic numerals
        const day = new Intl.DateTimeFormat("ar", { day: "numeric" }).format(
          date
        )

        // Format month name in Arabic
        const month = new Intl.DateTimeFormat("ar-EG", {
          month: "long"
        }).format(date)

        // Combine without spaces
        return `${day}${month}`
      } catch (error) {
        console.error("Error formatting short date:", error)
        return ""
      }
    },

    safeTranslate(key) {
      try {
        return this.$t(key)
      } catch (error) {
        console.error(`Translation error for key "${key}":`, error)
        return key
      }
    },

    copyShareLink(link) {
      navigator.clipboard.writeText(link)
      agToaster("success", this.$t("linkCopiedSuccessfully"))
    }
  }
}

export default useAssets
