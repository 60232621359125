import { ai } from "@/plugins/axios"
import type { IProfile, IUpdateProfileParams, IProfileResponse } from "./types"

class ProfileManageServices {
  /**
   * Get user profile details
   * @returns Promise with the profile details
   */
  static getProfile(): Promise<IProfileResponse> {
    return ai.get("v1/iam/users/me")
  }

  /**
   * Update user profile
   * @param payload IUpdateProfileParams containing profile data
   * @returns Promise with the update operation result
   */
  static updateProfile(payload: FormData) {
    return ai.patch("v1/iam/users/me", payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  }
  static updateAllProfile(payload: FormData) {
    return ai.put("v1/iam/users/me", payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  }

  /**
   * Update user password
   * @param payload Object containing old and new password
   * @returns Promise with the password update result
   */
  static updatePassword(payload: {
    old_password: string
    new_password: string
  }) {
    return ai.patch("v1/profile/password", payload)
  }

  // Add new email
  static addEmail(payload: { new_email: string }) {
    return ai.post("v1/iam/users/me/email/new/request", payload)
  }

  /**
   * Update user email
   * @param payload Object containing new email
   * @returns Promise with the email update result
   */
  static updateEmail(payload: any) {
    return ai.put("v1/iam/users/me/email", payload)
  }

  static updatePhone(payload: { mobile_number: string }) {
    return ai.put("v1/iam/users/me/mobile-number", payload)
  }
}

export default ProfileManageServices
