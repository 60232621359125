import mainLayout from "@/layout/mainLayout.vue"

// These routes will be nested under the space layout
export const spaceMemberRoutes = {
  path: "members",
  component: () => import("@/views/space/members/users/list.vue"),
  meta: {
    layout: mainLayout,
    authGard: true
  },
  name: "spaceMembers",
  props: true,
  children: [
    {
      path: "",
      name: "spaceMembersList",
      component: () => import("@/views/space/members/users/list.vue")
    },
    {
      path: "add",
      name: "addSpaceMember",
      component: () => import("@/views/space/members/users/add.vue")
    },
    {
      path: "edit/:member_id",
      name: "editSpaceMember",
      component: () => import("@/views/space/members/users/edit.vue")
    }
  ]
}

// Roles routes
export const spaceRoleRoutes = {
  path: "roles",
  component: () => import("@/views/space/members/roles/list.vue"),
  meta: {
    layout: mainLayout,
    authGard: true
  },
  name: "spaceRoles",
  props: true,
  children: [
    {
      path: "",
      name: "spaceRolesList",
      component: () => import("@/views/space/members/roles/list.vue")
    },
    {
      path: "add",
      name: "addSpaceRole",
      component: () => import("@/views/space/members/roles/add.vue")
    },
    {
      path: "edit/:role_id",
      name: "editSpaceRole",
      component: () => import("@/views/space/members/roles/edit.vue")
    }
  ]
}

// Groups routes
export const spaceGroupRoutes = {
  path: "groups",
  component: () => import("@/views/space/members/groups/spaceGroups.vue"),
  meta: {
    layout: mainLayout,
    authGard: true
  },
  name: "spaceGroups",
  props: true,
  children: [
    {
      path: "",
      name: "spaceGroupsList",
      component: () => import("@/views/space/members/groups/spaceGroups.vue")
    },
    {
      path: "add",
      name: "addSpaceGroup",
      component: () => import("@/views/space/members/groups/addGroup.vue")
    },
    {
      path: "edit/:group_id",
      name: "editSpaceGroup",
      component: () => import("@/views/space/members/groups/updateGroup.vue")
    }
  ]
}
