import { reactive } from 'vue'

import type { FunctionalComponent } from 'vue'

const typeAndColorMapping = {
  success: {
    icon: 'phCheck',
    style: 'bg-[#52C41A] !text-white  ',
  },
  error: {
    icon: 'phXCircle', 
    style: 'bg-[#F24B4B] !text-white  ',
  },
  warning: {
    icon: 'phWarning',
    style: 'bg-[#F79009] !text-white  ',
  },
  info: {
    icon: 'phInfo',
    style: 'bg-[#175CD3] !text-white  ',
  },
} as {
  [key: string]: {
    [key: string]: string | FunctionalComponent
  }
}

// notification interface
interface INotification {
  id?: number
  type: 'success' | 'error' | 'warning' | 'info'
  title: string
  message: string
  icon: string
  style?: string
}

interface INotificationPayload {
  title: string
  message?: string
}

const notifications: INotification[] = reactive([]) as INotification[]

const hideNotification = (id: number | undefined) => {
  if (!id)
    return
  const index = notifications.findIndex(item => item.id === id)
  if (index < 0)
    return

  notifications.splice(index, 1)
}

const createNotification = (type: 'success' | 'error' | 'warning' | 'info') => {
  return (payload: INotificationPayload) => {
    const notificationId = Math.random() + Date.now()
    const icon: string = (typeAndColorMapping[type]?.icon || 'phInfo') as string
    const style: string = (typeAndColorMapping[type]?.style || 'text-gray-400') as string

    notifications.push({
      id: notificationId,
      type,
      title: payload.title,
      message: payload.message || '',
      icon,
      style,
    })

    setTimeout(() => {
      hideNotification(notificationId)
    }, 2000)
  }
}

const showNotification = {
  success: createNotification('success'),
  error: createNotification('error'),
  warning: createNotification('warning'),
  info: createNotification('info')
}

export default function useNotifications() {
  return { notifications, showNotification, hideNotification, typeAndColorMapping }
}


