<template>
  <div :rtl="isRTL" :dir="localeDir">
    <div>
      <ag-toaster />
    </div>
    <!-- v-if="networkStatus" -->
    <component :is="layoutComponent" />
    <!-- <video
      src="blob:https://dancima.shop/00f670ab-9b3a-42f9-9e22-e369e6c7f7db"
    ></video> -->

    <!-- <offline v-else /> -->
  </div>
</template>
<script lang="ts" setup>
import { ref, computed, watch, onMounted, onBeforeUnmount } from "vue"
import { useNetwork } from "@vueuse/core"
// import { socket } from "@/plugins/socket"
import { ai } from "@/plugins/axios"
import { useAuthStore } from "./store/auth"
import { useRoute } from "vue-router"

// Network status handling
const { isOnline } = useNetwork()

// State variables
const space_id = ref<string>("")
const userId = ref<string>("")
const memberId = ref<string>("")
const userLogged = ref<boolean>(false)
const networkStatus = ref<boolean>(true)

// i18n and routing setup
const route = useRoute()
// const localeDir = computed(() => (route.meta.locale === "ar" ? "rtl" : "ltr"))
// const isRTL = computed(() => route.meta.locale === "ar")
const localeDir = "ar"
const isRTL = "ar"
const layoutComponent = computed(() => route.meta.layout)

// Watcher for space_id
watch(
  space_id,
  (newVal, oldVal) => {
    // Handler logic here
  },
  { deep: true }
)

// watch if route change

// Lifecycle hooks
onMounted(() => {
  const locale = localStorage.getItem("locale")
  const theme = localStorage.getItem("theme")

  if (locale) {
    route.meta.locale = locale
  }

  if (theme) {
    // Assuming theme handling logic with Vuetify
  }

  if (localStorage.getItem("space_id")) {
    space_id.value = localStorage.getItem("space_id") || ""
  }

  localStorage.getItem("tour", true)

  // User authentication handling

  // Socket handling
  // socket.on(memberId.value, (...args) => {
  //   const res = args[0]
  // })

  // Handle network status
  networkStatus.value = isOnline.value
})

onBeforeUnmount(() => {
  // window.removeEventListener("offline", handleOnlineStatus)
})
</script>

<style></style>
