import mainLayout from "@/layout/mainLayout.vue"

const filesRoutes = [
  {
    path: "/entities/:entity_id/space/:space_id",
    meta: {
      layout: mainLayout,
      authGard: true
    },
    component: () => import("@/views/space/spaceLayout.vue"),
    children: [
      {
        path: "files",
        name: "files",
        meta: {
          layout: mainLayout,
          authGard: true
        },
        component: () => import("@/views/space/files/list.vue")
      },
      // File View
      {
        path: "files/view/:file_id",
        name: "fileView",
        meta: {
          layout: mainLayout,
          authGard: true
        },
        component: () => import("@/views/space/files/view.vue"),
      },

      // folder details
      {
        path: "files/folder/:folder_id",
        name: "folderDetails",
        component: () => import("@/views/space/files/folderDetails.vue")
      },
      // File View
      {
        path: "files/annotation/:file_url/:file_name/:file_id",
        name: "annotation",
        meta: {
          layout: mainLayout,
          authGard: false
        },
        component: () => import("@/views/Annotation.vue")
      }
    ]
  }
]

export default filesRoutes
