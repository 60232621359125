import { ai } from "@/plugins/axios"
import { defineStore } from "pinia"

interface SystemData {
  system: Record<string, any>
}

export const useTanentStore = defineStore({
  id: "Tanent",
  state: () => ({
    system: {} as Record<string, any>
  }),
  getters: {
    getSystemData(): Record<string, any> {
      return this.system
    }
  },
  actions: {
    setSystemData(systemData: SystemData) {
      this.system = systemData.system
      // Save to localStorage
      localStorage.setItem("systemData", JSON.stringify(systemData.system))
    },

    isSystemDataExists() {
      const storedData = localStorage.getItem("systemData")
      if (storedData) {
        this.system = JSON.parse(storedData)
        return true
      }
      return false
    },

    async fetchSystemData() {
      // First try to load from localStorage
      if (this.isSystemDataExists()) {
        return this.system
      }

      try {
        const { data } = await ai.get("v1/iam/system/details")
        this.setSystemData({ system: data.data })

        return data
      } catch (error) {
        console.error("Error fetching system data:", error)
        throw error
      }
    }
  }
})
