import mainLayout from "@/layout/mainLayout.vue"
import memberRoutes from "./memberRoutes"

const entitySettings = [
  {
    path: "/entity/:entity_id/settings",
    meta: {
      layout: mainLayout,
      authGard: true
    },
    component: () => import("@/views/entity/settings/EntityLayout.vue"),
    children: [
      {
        path: "basicData",
        name: "entity-settings-basicData",
        component: () => import("@/views/entity/settings/basicData.vue")
      },
      {
        path: "preferences",
        name: "entity-settings-preferences",
        component: () => import("@/views/entity/settings/preferences.vue")
      },
      memberRoutes
    ]
  }
]

export default entitySettings
