import mainLayout from "@/layout/mainLayout.vue"
import {
  spaceMemberRoutes,
  spaceRoleRoutes,
  spaceGroupRoutes
} from "./memberRoutes"

const spaceRoutes = [
  {
    path: "/entities/:entity_id/space/:space_id",
    meta: {
      layout: mainLayout,
      authGard: true
    },
    component: () => import("@/views/space/spaceLayout.vue"),
    children: [
      {
        path: "",
        name: "space",
        meta: {
          layout: mainLayout,
          authGard: true
        },
        component: () => import("@/views/space/summary/space.vue")
      },
      {
        path: "coming-soon",
        name: "comingSoon",
        meta: {
          layout: mainLayout,
          authGard: true
        },
        component: () => import("@/views/space/comingSoon.vue")
      },
      // space settings
      {
        path: "spaceSettings",
        name: "spaceSettings",
        meta: {
          layout: mainLayout,
          authGard: true
        },
        component: () =>
          import("@/views/space/settings/spaceSettingsLayout.vue"),
        children: [
          {
            path: "" || "spaceBasicData",
            name: "spaceBasicData",
            meta: {
              layout: mainLayout,
              authGard: true
            },
            component: () => import("@/views/space/settings/basicData.vue")
          },
          {
            path: "preference",
            name: "preference",
            meta: {
              layout: mainLayout,
              authGard: true
            },
            component: () => import("@/views/space/settings/preference.vue")
          },
          {
            path: "spaceRotation",
            name: "spaceRotation",
            meta: {
              layout: mainLayout,
              authGard: true
            },
            component: () => import("@/views/space/settings/spaceRotation.vue")
          },
          {
            path: "spaceTrash",
            name: "spaceTrash",
            meta: {
              layout: mainLayout,
              authGard: true
            },
            component: () => import("@/views/space/settings/spaceTrash.vue")
          }
        ]
      },
      // Members section
      {
        path: "members",
        name: "spaceMembers",
        component: () => import("@/views/space/members/users/list.vue"),
        meta: {
          layout: mainLayout,
          authGard: true
        }
      },
      {
        path: "members/add",
        name: "addSpaceMember",
        component: () => import("@/views/space/members/users/add.vue"),
        meta: {
          layout: mainLayout,
          authGard: true
        }
      },
      {
        path: "members/edit/:member_id",
        name: "editSpaceMember",
        component: () => import("@/views/space/members/users/edit.vue"),
        meta: {
          layout: mainLayout,
          authGard: true
        }
      },
      // Roles section
      {
        path: "members/roles",
        name: "spaceRoles",
        component: () => import("@/views/space/members/roles/list.vue"),
        meta: {
          layout: mainLayout,
          authGard: true
        }
      },
      {
        path: "members/roles/add",
        name: "addSpaceRole",
        component: () => import("@/views/space/members/roles/add.vue"),
        meta: {
          layout: mainLayout,
          authGard: true
        }
      },
      {
        path: "members/roles/edit/:role_id",
        name: "editSpaceRole",
        component: () => import("@/views/space/members/roles/edit.vue"),
        meta: {
          layout: mainLayout,
          authGard: true
        }
      },
      // Calendar section
      {
        path: "calendar",
        name: "calendar",
        component: () => import("@/views/space/calender/table.vue"),
        meta: {
          layout: mainLayout,
          authGard: true
        }
      },
      // Groups section
      spaceGroupRoutes
    ]
  },
  {
    path: "/entities/:entity_id/space/create",
    name: "createSpace",
    meta: {
      layout: mainLayout,
      authGard: true
    },
    component: () => import("@/views/space/create.vue")
  }
]

export default spaceRoutes
