<template>
  <div class="flex algin-center justify-start">
    <div class="ag-main-taps flex algin-center gap-2 p-[4px]">
      <slot name="taps"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss">
.ag-main-taps {
  border-radius: 999px;
  // border: 1px solid rgba(255, 255, 255, 0.2);
  background: #f9f9f9;
  backdrop-filter: blur(10px);
  padding: 4px;
  overflow: auto;
  width: fit-content;

  .tap-item {
    color: #313f52;
    font-weight: medium;
    display: flex;
    height: 36px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    transition: background 0.2s linear;
    font-size: 14px;
    flex-shrink: 0;
    border-radius: 40px;
    &.active,
    &.router-link-active {
      border-radius: var(--border-radius-super, 999px);
      background: #fff;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16) !important;
    }

    &.slide {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: #1c292f;
        transition: left 0.2s linear;
        border-radius: 40px;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  .active.slide::before {
    left: 0;
  }
}
</style>
