<template>
  <div class="fixed bottom-5 right-5 flex flex-col gap-2 z-[999999]">
    <div
      v-for="notification in notifications"
      :key="notification.id"
      class="p-3 rounded-lg shadow-[0px_1.75px_8px_-1px_#0F111414] bg-[#FFFFFFED] flex gap-3 items-center"
    >
      <span
        class="text-white rounded-full flex items-center justify-center"
        :class="containerClasses(notification.style || '')"
      >
        <ag-icon :name="notification.icon" size="lg" color="white" />
      </span>

      <div class="toaster-text">
        <p class="text-[#313F52] text-sm font-semibold">
          {{ notification.title }}
        </p>
        <p class="text-sm text-[#313F52]">
          {{ notification.message }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { notifications } = useNotification()

const containerClasses = (bgColor: string) => {
  return `${bgColor}  rounded-full p-[10px]`
}
</script>
