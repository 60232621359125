import axios from "axios"
import router from "../router"
import { storeToRefs } from "pinia"
import moment from "moment"
import { useAuthStore } from "@/store/auth"

export const ai = axios.create({
  baseURL:
    import.meta.env.VITE_BASE_URL && import.meta.env.VITE_BASE_URL !== "false"
      ? import.meta.env.VITE_BASE_URL + "/api/"
      : undefined,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json"
  }
})

let isRefreshing = true
const prepareHeaders = (config, { token, lang }) => {
  config.headers = {
    ...config.headers,
    Authorization: token,
    "Accept-Language": lang
  }
}
ai.interceptors.request.use(
  async (config) => {
    const { getUser } = storeToRefs(useAuthStore())
    const { refreshToken } = useAuthStore()
    const expireToken = getUser.value?.exp
    let userToken = getUser.value?.access_token

    if (!userToken) {
      return config
    }

    prepareHeaders(config, {
      token: userToken ? "Bearer " + userToken : "",
      lang: "ar"
    })

    if (userToken) {
      let tokenExpiredAfterOneMinute =
        expireToken && moment(expireToken * 1000).diff(moment(), "minutes") <= 5
      if (tokenExpiredAfterOneMinute && isRefreshing) {
        isRefreshing = false
        await refreshToken({
          refresh_token: getUser.value?.refresh_token,
          token: getUser.value?.access_token
        })
        isRefreshing = true
        // reprepare headers with new token
        prepareHeaders(config, {
          token: "Bearer " + getUser.value?.access_token,
          lang: "ar"
        })
        return config
      }
    }
    return config
  },

  (error) => {
    return Promise.reject(error)
  }
)

ai.interceptors.response.use(
  (response) => {
    return Promise.resolve({ data: response.data, body: response })
  },
  async (error) => {
    console.error("API Error:", error.response || error)

    if (error.response?.status === 403) {
      router.push("/")
      return Promise.reject(
        error.response.data.message.error || "ليس لديك صالحيه لهذه العمليه "
      )
    }

    if (
      error.response?.status === 401 &&
      router.currentRoute.value.name !== "login"
    ) {
      router.push("/login")
      localStorage.clear()
      return Promise.reject(
        error.response.data.message.error || "Authentication required"
      )
    }

    if (error.response?.status === 417) {
      const errorMsg =
        error.response?.data?.message?.error ??
        "حدث خطأ ما , يرجى المحاولة مرة اخرى"
      agToaster("error", errorMsg)
      return Promise.reject(errorMsg)
    }

    if (error.response?.status === 500) {
      const errorMsg =
        error.response?.data?.message?.error ??
        "حدث خطأ ما , يرجى المحاولة مرة اخرى"
      agToaster("error", errorMsg)
      return Promise.reject(errorMsg)
    }
    return Promise.reject(error.response || error)
  }
)
