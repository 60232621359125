import { defineStore } from "pinia"
import AuthManageServices from "@/services/auth"
import type { LoggedInUser } from "@/types/auth"

export const useAuthStore = defineStore({
  id: "Auth",
  state: () => ({
    user: JSON.parse(
      localStorage.getItem("user") || "{}"
    ) as LoggedInUser | null,
    entityId: ""
  }),
  getters: {
    getUser: (state) => state.user as LoggedInUser
  },
  actions: {
    setUser(userInfo: LoggedInUser | null) {
      this.user = userInfo
      if (this.user) {
        localStorage.setItem("user", JSON.stringify(this.user))
      } else {
        localStorage.removeItem("user")
      }
    },

    async refreshToken({
      refresh_token,
      token
    }: {
      refresh_token: string
      token: string
    }) {
      try {
        const { updateAccount } = useFastLogin()
        const { data } = await AuthManageServices.refreshToken(
          refresh_token,
          token
        )
        const { exp } = parseJwt(data.data.auth.access_token)
        // set user new refresed token and refresh token to store
        this.setUser({
          ...data.data.auth,
          exp: exp
        })
        // get user info to update user info in store
        const { data: userInfoResponse } = await AuthManageServices.getMe()
        this.setUser({
          ...data.data.auth,
          ...userInfoResponse.data.user,
          exp
        })
        // update account in saved accounts in fastlogin in local storage
        updateAccount(this.user as LoggedInUser)
        return data
      } catch (error) {
        console.error("Error refreshing token:", error)
        throw error
      }
    }
  }
})
