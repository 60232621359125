<template>
  <div
    class="flex items-center justify-center shrink-0"
    :style="containerStyles"
  >
    <img
      v-if="fromLocal"
      :src="getImageFromAssets(`images/icons/${name}.svg`)"
      :style="iconStyles"
      alt="icon"
    />
    <component
      v-else
      :weight="weight"
      :is="name"
      :size="sizeMap[size]"
      :color="error ? '#F24B4B' : color"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, type CSSProperties } from "vue"
import type { PropType } from "vue"

type IconSize = "sm" | "md" | "lg" | "xl"
type TonalShape = "circle" | "square"

const sizeMap = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 40
} as const

const tonalSizeMap = {
  sm: 24,
  md: 40,
  lg: 44
} as const

const props = defineProps({
  name: {
    type: String as PropType<string>,
    required: true
  },
  error: {
    type: Boolean as PropType<boolean>,
    default: false
  },
  size: {
    type: String as PropType<IconSize>,
    default: "sm"
  },
  color: {
    type: String as PropType<string>,
    default: "#313F52"
  },
  weight: {
    type: String as PropType<string>,
    default: "regular"
  },
  fromLocal: {
    type: Boolean as PropType<boolean>,
    default: false
  },
  // Main determiner for tonal background
  tonal: {
    type: Boolean as PropType<boolean>,
    default: false
  },
  // Optional custom color for tonal background
  tonal_color: {
    type: String as PropType<string>,
    default: "#F2F4F7" // Default color when tonal is true
  },
  tonalSize: {
    type: String as PropType<IconSize>,
    default: "md"
  },
  tonalShape: {
    type: String as PropType<TonalShape>,
    default: "square"
  }
})

const containerStyles = computed((): CSSProperties => {
  if (!props.tonal) return {}

  const size = tonalSizeMap[props.tonalSize]
  return {
    width: `${size}px`,
    height: `${size}px`,
    backgroundColor: props.error ? "#FEEDED" : props.tonal_color,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: props.tonalShape === "circle" ? "50%" : "8px"
  }
})

const iconStyles = computed(
  (): CSSProperties => ({
    width: `${sizeMap[props.size]}px`,
    height: `${sizeMap[props.size]}px`,
    display: "inline-block",
    objectFit: "contain"
  })
)
</script>

<style scoped>
img {
  display: inline-block;
  object-fit: contain;
}

.tonal-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
</style>
