import { ref, computed, watch, onMounted } from "vue"
import { useAuthStore } from "@/store/auth"
import { useRouter } from "vue-router"
import AuthManageServices from "@/services/auth"
import type { LoggedInUser } from "@/types"
import { useStorage } from "@vueuse/core"

/**
 * User interface representing a logged-in user
 */

/**
 * Hook for managing fast login functionality
 * Provides methods for user authentication and account switching
 */
export const useFastLogin = () => {
  const authStore = useAuthStore()
  const router = useRouter()
  const loading = ref(false)
  const isConfirmModalOpen = ref(false)

  // Use useStorage for reactive localStorage access
  const savedAccounts = useStorage<LoggedInUser[]>("accountsLoggedIn", [])

  /**
   * Get saved logged-in users as a computed property
   */
  const getSavedAccounts = computed(() => savedAccounts.value)

  const addAccount = (user: LoggedInUser) => {
    const existingUser = savedAccounts.value.find(
      (u: LoggedInUser) => u.id === user?.id
    )
    if (existingUser) return
    savedAccounts.value.push(user)
  }

  const updateAccount = (updatedAccount: LoggedInUser) => {
    const index = savedAccounts.value.findIndex(
      (u: LoggedInUser) => u.id === updatedAccount.id
    )
    if (index !== -1) {
      savedAccounts.value[index] = updatedAccount
    }
  }

  const removeAccount = (userToRemove: LoggedInUser) => {
    savedAccounts.value = savedAccounts.value.filter(
      (user: LoggedInUser) => user.id !== userToRemove.id
    )
  }

  /**
   * Handle user login when clicked from the saved users list
   * @param account - The account to log in
   */
  const loginWithSavedAccount = async (account: LoggedInUser) => {
    loading.value = true
    isConfirmModalOpen.value = false
    const { refreshToken } = authStore
    try {
      const accounts = getSavedAccounts.value
      const getAccount = accounts.find((u: LoggedInUser) => u.id === account.id)
      if (getAccount && getAccount.access_token) {
        // refresh account token to get valid token before login (to handle if token is expired)
        await refreshToken({
          refresh_token: getAccount.refresh_token,
          token: getAccount.access_token
        })
        router.push({ name: "entities" })
      }
    } catch (error) {
      console.error("Error logging in with saved account:", error)
      try {
        const { data } = await AuthManageServices.requestLoginOtp(account.email)
        router.push({
          name: "verificationCodeLogin",
          query: {
            email: account.email,
            verification_code: data.data.verification_code
          }
        })
      } catch (otpError) {
        console.error("Error requesting OTP:", otpError)
      }
    } finally {
      loading.value = false
    }
  }

  /**
   * Switch to a different user account
   * @param user - The user to switch to
   */

  /**
   * Switch between different user accounts
   * Logs out current user and logs in the selected user
   * @param user - The user to switch to
   */
  const switchAccount = async (account: LoggedInUser) => {
    try {
      // Clear current user session
      authStore.setUser(null)

      await loginWithSavedAccount(account)
      agToaster("success", "accountSwitchedSuccessfully")
      // reload page to update user
    } catch (error) {
      console.error("Error switching accounts:", error)
    }
  }

  return {
    loading,
    isConfirmModalOpen,
    getSavedAccounts,
    addAccount,
    updateAccount,
    removeAccount,
    loginWithSavedAccount,
    switchAccount
  }
}
