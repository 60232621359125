import { ai } from "@/plugins/axios"
import type {
  ILoginOtpVerifyParams,
  IRegistrationOtpVerifyParams,
  ICompleteRegistrationParams,
  IPasswordResetParams,
  IResetPasswordParams
} from "@/types/auth"

class AuthManageServices {
  // Passwordless login
  static requestLoginOtp(user: string) {
    return ai.post("v1/iam/auth/login/passwordless", { user })
  }

  static verifyLoginOtp(params: ILoginOtpVerifyParams) {
    return ai.post("v1/iam/auth/login/verify", params)
  }

  // Passwordless registration
  static requestRegistrationOtp(email: string) {
    return ai.post("v1/iam/auth/register/request", { email })
  }

  static verifyRegistrationOtp(params: IRegistrationOtpVerifyParams) {
    return ai.post("v1/iam/auth/register/verify", params)
  }

  static completeRegistration(params: ICompleteRegistrationParams) {
    return ai.post("v1/iam/auth/register", params)
  }

  // Password-based auth
  static requestPasswordReset(params: IPasswordResetParams) {
    return ai.get("method/agenda_manager.api.auth.auth.forget_password", {
      params
    })
  }

  static resetPassword(params: IResetPasswordParams) {
    return ai.post(
      "method/agenda_manager.api.auth.auth.set_new_password",
      null,
      {
        params
      }
    )
  }

  static getMe() {
    return ai.get("v1/iam/users/me")
  }

  static refreshToken(refresh_token: string, token: string) {
    return ai.post(
      "/v1/iam/auth/token/refresh",
      {
        refresh_token
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
  }
}

export default AuthManageServices
