import mainLayout from "@/layout/mainLayout.vue"

const entityMemberRoutes = {
  path: "members",
  meta: {
    layout: mainLayout,
    authGard: true
  },
  children: [
    // Users routes
    {
      path: "users",
      children: [
        {
          path: "",
          name: "entityMembers",
          component: () =>
            import("@/views/entity/settings/members/users/list.vue")
        },
        {
          path: "add",
          name: "addEntityMember",
          component: () =>
            import("@/views/entity/settings/members/users/add.vue")
        },
        {
          path: "edit/:member_id",
          name: "editEntityMember",
          component: () =>
            import("@/views/entity/settings/members/users/edit.vue")
        }
      ]
    },
    // Roles routes
    {
      path: "roles",
      children: [
        {
          path: "",
          name: "entityRoles",
          component: () =>
            import("@/views/entity/settings/members/roles/list.vue")
        },
        {
          path: "add",
          name: "addEntityRole",
          component: () =>
            import("@/views/entity/settings/members/roles/add.vue")
        },
        {
          path: "edit/:role_id",
          name: "editEntityRole",
          component: () =>
            import("@/views/entity/settings/members/roles/edit.vue")
        }
      ]
    }
  ]
}

export default entityMemberRoutes
