/* eslint-disable no-console */

import { register } from "register-service-worker"

// Add a global error handler to catch and suppress extension-related errors
window.addEventListener("error", function (event) {
  // Check if the error is related to a browser extension (contains FrameDoesNotExistError)
  if (
    event.error &&
    event.error.message &&
    event.error.message.includes("FrameDoesNotExistError")
  ) {
    console.warn("Suppressed browser extension error:", event.error.message)
    event.preventDefault()
    return true // Prevents the error from bubbling up
  }
})

if (import.meta.env.NODE_ENV === "production") {
  register(`${import.meta.env.VITE_BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB"
      )
    },
    registered() {
      console.log("Service worker has been registered.")
    },
    cached() {
      console.log("Content has been cached for offline use.")
    },
    updatefound() {
      console.log("New content is downloading.")
    },
    updated() {
      console.log("New content is available; please refresh.")
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      )
    },
    error(error) {
      // Filter out extension-related errors
      if (
        error &&
        error.message &&
        error.message.includes("FrameDoesNotExistError")
      ) {
        console.warn(
          "Suppressed extension error in service worker:",
          error.message
        )
        return
      }
      console.error("Error during service worker registration:", error)
    }
  })
}
