import { createApp } from "vue"
import App from "@/App.vue"
import router from "@/router"
import VueTelInput from "vue-tel-input"
import "vue-tel-input/vue-tel-input.css"

import "@/assets/style/main.scss"
import VOtpInput from "vue3-otp-input"
import VCalendar from "v-calendar"
import "v-calendar/style.css"

import { createPinia } from "pinia"

const pinia = createPinia()

import useAssets from "@/mixins/useAssets"
import "@/js/shephard.js"
import "./registerServiceWorker"
import vue3GoogleLogin from "vue3-google-login"
import PhosphorIcons from "@phosphor-icons/vue"
import i18n from "@/plugins/i18n"

// Import moment configuration
// import "./moment-config.ts";

// Import VueDatetimeJs after configuring moment-jalaali
// import VueDatetimeJs from "vue-datetime-js";

import VueDatePicker from "@vuepic/vue-datepicker"
import "@vuepic/vue-datepicker/dist/main.css"

// Add global error handler to catch and suppress extension-related errors
window.addEventListener("unhandledrejection", (event) => {
  if (
    event.reason &&
    typeof event.reason.message === "string" &&
    (event.reason.message.includes("FrameDoesNotExistError") ||
      (event.reason.message.includes("Frame") &&
        event.reason.message.includes("does not exist")))
  ) {
     event.preventDefault()
  }
})

const app = createApp(App)
  .mixin(useAssets)
  .use(router)
  .use(pinia)
  .use(i18n)
  .use(VueTelInput)
  .use(VOtpInput)
  // .use("date-picker")
  .use(VCalendar)
  .use(PhosphorIcons)
  .use(VueDatePicker)

  .use(vue3GoogleLogin, {
    clientId: import.meta.env.VITE_BASE_URL
  })
// .use(VueDatetimeJs); // Use VueDatetimeJs after configuring moment-jalaali

app.config.performance = true

// Configure global error handler
app.config.errorHandler = (err, instance, info) => {
  // Check if it's a browser extension error
  if (
    err &&
    err.message &&
    (err.message.includes("FrameDoesNotExistError") ||
      (err.message.includes("Frame") && err.message.includes("does not exist")))
  ) {
    console.warn("Suppressed Vue error from browser extension:", err.message)
    return // Prevent the error from propagating
  }

  // For other errors, log them properly
  console.error("Application error:", err)
  console.error("Component:", instance)
  console.error("Error info:", info)
}

app.mount("#app")
