import mainLayout from "@/layout/mainLayout.vue"

const taskRoutes = [
  {
    path: "/entities/:entity_id/space/:space_id",
    meta: {
      layout: mainLayout,
      authGard: true
    },
    component: () => import("@/views/space/spaceLayout.vue"),
    children: [
      {
        path: "tasks",
        name: "tasks",
        meta: {
          layout: mainLayout,
          authGard: true
        },
        component: () => import("@/views/space/tasks/list.vue")
      },
      {
        path: "add",
        name: "addTask",
        component: () => import("@/views/space/tasks/add.vue"),
        meta: {
          layout: mainLayout,
          authGard: true
        }
      },
      {
        path: "task/:task_id",
        name: "editTask",
        component: () => import("@/views/space/tasks/edit.vue"),
        meta: {
          layout: mainLayout,
          authGard: true
        }
      }
    ]
  }
]

export default taskRoutes
