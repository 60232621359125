<template>
  <Menu as="div" class="relative" v-slot="{ close }">
    <div class="flex items-center">
      <MenuButton @click.stop="setPosition">
        <slot name="button"></slot>
      </MenuButton>
    </div>

    <MenuItems
      ref="menuRef"
      class="menu_items_container absolute divide-y divide-gray-100 rounded-[12px] bg-white shadow-lg ring-1 ring-black/5 focus:outline-none z-30 !px-2"
      :class="[
        props.position,
        dropdownPosition === 'top' ? 'bottom-full mb-2' : 'top-full mt-2',
        $attrs.class
      ]"
    >
      <transition
        enter-active-class="transition duration-100 ease-out "
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
      >
        <slot name="content" :close="close"></slot>
      </transition>
    </MenuItems>
  </Menu>
</template>

<script setup>
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue"

const props = defineProps({
  position: {
    type: String,
    default: "end-0"
  }
})

const menuRef = ref(null)
const dropdownPosition = ref("bottom")

const setPosition = () => {
  const button = document.activeElement
  if (!button) return

  const buttonRect = button.getBoundingClientRect()
  const windowHeight = window.innerHeight
  const spaceBelow = windowHeight - buttonRect.bottom
  const spaceAbove = buttonRect.top
  const menuHeight = 200 // Estimated menu height
  const buffer = 20

  if (spaceBelow >= menuHeight + buffer) {
    dropdownPosition.value = "bottom"
  } else if (spaceAbove > spaceBelow) {
    dropdownPosition.value = "top"
  } else {
    dropdownPosition.value = "bottom"
  }
}

onMounted(() => {
  // Add event listeners with debounce for better performance
  let timeout
  const debouncedCheck = () => {
    clearTimeout(timeout)
    timeout = setTimeout(setPosition, 100)
  }

  window.addEventListener("scroll", debouncedCheck, { passive: true })
  window.addEventListener("resize", debouncedCheck, { passive: true })

  // Cleanup event listeners
  onBeforeUnmount(() => {
    clearTimeout(timeout)
    window.removeEventListener("scroll", debouncedCheck)
    window.removeEventListener("resize", debouncedCheck)
  })
})
</script>
