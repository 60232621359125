<template>
  <div class="header-avatar-holder" :class="containerClasses">
    <img
      :key="srcUrl || src"
      loading="lazy"
      class="header-avatar"
      :class="imgClasses"
      :src="imageSource"
      :alt="alt"
      @error="handleImageError"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import defaultAvatar from '@/assets/images/icons/user_placeholder.svg'

interface Props {
  containerClasses?: string
  imgClasses?: string
  src?: string
  srcUrl?: string
}

const props = withDefaults(defineProps<Props>(), {
  containerClasses: "",
  imgClasses: "",
  src: "",
})

const alt = computed(() => props.src || "Avatar")

const imageSource = computed(() => {
  return props.srcUrl || props.src || defaultAvatar
})

const handleImageError = (e: Event) => {
  const target = e.target as HTMLImageElement
  target.src = defaultAvatar
}
</script>