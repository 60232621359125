import { ai } from "@/plugins/axios"
import { defineStore } from "pinia"
export const useLayoutStore = defineStore({
  id: "Layout",
  state: () => ({
    color: "",
    userImgUrl: "",
  }),
  getters: {},
  actions: {
    setColor(color:any) {
      this.color = color
    },
    setUserImgUrl(url:any) {
      this.userImgUrl = url
    },
  }
})
