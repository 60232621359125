import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'
export const useUserStore = defineStore('user', {
    state: () => ({
        user: useStorage('user', {}),
    }),

    getters: {
        getUserInfo: (state) => state.user,
    },

    actions: {
        setUser(userData: any) {
            this.user = { ...this.user, ...userData }
        },

        clearUser() {
            this.user = {}
        },
    },
})